import React from 'react'
import ConnectButton from './ConnectButton';
import logoImg from '../Assets/img/logo.jpeg';
import { Link } from 'react-router-dom';

export default function Header() {
    return (
        // <header>
        //     <nav class="border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        //         <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
        //             <a href="https://flowbite.com" class="flex items-center">
        //                 <img src={logoImg} class="mr-3 logo-img" alt="Flowbite" />
        //             </a>
        //             <div class="flex items-center lg:order-2">
        //                 <ConnectButton className="btn-primary" />
        //             </div>
        //         </div>
        //     </nav>
        // </header>

        <nav class="navbar navbar-expand-md navbar-dark">
            <div class="container-fluid">
                <a href="https://flowbite.com" class="navbar-brand">
                    <img src={logoImg} class="mr-3 logo-img" alt="Flowbite" />
                </a>
                <ConnectButton className="btn-primary desk-none" />
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <Link to="/leader-board" class="nav-link active" aria-current="page">Top Users</Link>
                        </li>
                        <li class="nav-item">
                            <Link to="/faqs" class="nav-link active" aria-current="page" href="#">FAQs</Link>
                        </li>
                        
                    </ul>
                    
                    <ConnectButton className="btn-primary mob-none" />
                </div>
            </div>
        </nav>
    )
}
