import React from 'react';
import { formatPrice } from '../helper/functions';

export default function StaticsBox({commonStats }) {
    const staticsData = [
        {
            title: "Total Members",
            value: `${commonStats.totalMember}`,
        },
        {
            title: "Total BNB Invested",
            value: `${formatPrice(commonStats.contract_balance)} BNB`,
        }
    ];

    return (
        <div className='row d-flex page-margin'>
            {staticsData.map((card, index) => (
                <div className='col-12 col-md-4 mt-3'>
                    <div key={index} className="inrBox shadow rounded-lg p-4 flex justify-between items-center">
                        <div>
                            <h3 className="font-bold">{card.title}</h3>
                            <p className="text-sm text-gray-500">{card.value}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
