import React, { useState } from 'react'
import Header from '../Components/Header';
import StaticsBox from '../Components/StaticsBox';
import MainSection from '../Components/MainSection';
import { useAccountStats, useCommonStats } from '../hooks/useCustomStats';

export default function Home() {
    const [updater, setUpdater] = useState(1);
    const accStats = useAccountStats(updater);
    const commonStats = useCommonStats(updater);
    return (
        <div class="mx-auto">
            <Header />
            <StaticsBox accStats={accStats}  commonStats={commonStats}  />
            <MainSection accStats={accStats}  commonStats={commonStats} setUpdater={setUpdater} />
        </div>
    )
}
