import React, { useState } from 'react'
import Deposit from './Deposit'
import { useAccount } from 'wagmi';
import { formatPrice } from '../helper/functions';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { Tooltip } from '@mui/material';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { styled } from '@mui/material/styles';

export default function MainSection({ accStats, commonStats, setUpdater }) {
    const [copy, setCopy] = useState("Copy Link");
    const { address } = useAccount();

    const CustomTooltip = styled(Tooltip)(({ theme }) => ({
        [`& .MuiTooltip-tooltip`]: {
            fontSize: '25px', // Adjust font size as needed
        },
    }));

    const staticsData = [
        {
            title: "Your Balance",
            value: `${accStats.bnbBal ? formatPrice(accStats.bnbBal, 5) : 0} BNB`,
            isToolTip: false,
        },
        {
            title: "Your Benas",
            value: `${accStats.getMyMiners ? parseInt(accStats.getMyMiners) : 0} BEANS`,
            isToolTip: false
        },
        {
            title: "Your Level",
            value:
                Array.from({ length: 6 }).map((_, index) => {
                    if (parseInt(index) < parseInt(accStats.starRatings)) {
                        return <StarIcon key={index} sx={{ color: "#fff" }} />;
                    } else {
                        return <StarBorderIcon key={index} sx={{ color: "#fff" }} />;
                    }
                }),
            isToolTip: true,
            tiptext: `<ul>
                        <li>1-Star Player Deposit 0.15 BNB</li>
                        <li>2-Star Player Deposit 0.8 BNB</li>
                        <li>3-Star Player Deposit 1.61 BNB</li>
                        <li>4-Star Player Deposit 3.51 BNB</li>
                        <li>5-Star Player Deposit 4.51 BNB</li>
                        <li>6-Star Player Deposit 5 BNB</li>
                        </ul>`

        },
        {
            title: "Total Deposit",
            value: `${accStats.userDeposits ? formatPrice(accStats.userDeposits) : 0}  BNB`,
            isToolTip: false,
        },
        {
            title: "Total Withdraw",
            value: `${accStats.userClaims ? formatPrice(accStats.userClaims) : 0} / ${accStats.userDeposits ? formatPrice(accStats.userDeposits * 2) : 0}  BNB`,
            isToolTip: true,
            tiptext: "Maximum received 2X"
        },
        {
            title: "Direct Memeber",
            value: `${accStats.levelRefCount && accStats.levelRefCount.length > 0 ? accStats.levelRefCount[0] : 0}`,
            isToolTip: false,
        },
    ];

    return (
        <section className='mt-5 page-margin'>
            <div className='row d-flex align-items-center '>
                <div className='col-12 col-md-7 order-2 order-md-1'>
                    <div className='row d-flex justify-content-center'>
                        {staticsData.map((card, index) => (
                            <div className='col-12 col-md-4 mt-3'>
                                <div key={index} className="inrBox shadow rounded-lg p-4 flex justify-between items-center">
                                    <div>
                                        <h3 className="font-bold">{card.title}
                                            {card.isToolTip &&
                                                <CustomTooltip title={<span dangerouslySetInnerHTML={{ __html: card.tiptext }} />} className="mx-1">
                                                    <HelpOutlineRoundedIcon sx={{ color: "#040054" }} fontSize="small" />
                                                </CustomTooltip>
                                            }
                                        </h3>
                                        <p className="text-sm text-gray-500">{card.value}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className='col-12 col-md-10 mt-10'>
                            <div className="card card-referral mt-3">
                                <div className="card-body">
                                    <h5 className="card-referral-title">Referral Link</h5>
                                    <input
                                        readOnly=""
                                        className="copy-input"
                                        value={address ? `${window.location.origin}?ref=${address}` : 'Please connect wallet'}
                                    ></input>
                                    <button
                                        style={{
                                            backgroundColor: "rgb(80, 196, 237)",
                                            marginTop: "10px",
                                        }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                address ? `${window.location.origin}?ref=${address}` : 'Please Connect Wallet'
                                            );
                                            setCopy("Copied");
                                            setTimeout(() => {
                                                setCopy("Copy Link");
                                            }, 600);
                                        }}
                                        className="btn-comman"
                                    >
                                        {copy}
                                    </button>
                                    <div className="d-flex justify-content-around my-2 text-white" style={{ fontSize: "12px" }}>
                                        <div> {Array.from({ length: 1 }).map((_, index) => {
                                            return <StarIcon style={{fontSize : "12px"}} key={index} sx={{ color: "#fff" }} />;
                                        })} L1: {commonStats.level1}%</div>
                                        <div>{Array.from({ length: 2 }).map((_, index) => {
                                            return <StarIcon style={{fontSize : "12px"}} key={index} sx={{ color: "#fff" }} />;
                                        })} L2: {commonStats.level2}%</div>
                                        <div>{Array.from({ length: 3 }).map((_, index) => {
                                            return <StarIcon style={{fontSize : "12px"}} key={index} sx={{ color: "#fff" }} />;
                                        })} L3: {commonStats.level3}%</div>
                                        <div>{Array.from({ length: 4 }).map((_, index) => {
                                            return <StarIcon style={{fontSize : "12px"}} key={index} sx={{ color: "#fff" }} />;
                                        })} L4: {commonStats.level4}%</div>
                                        <div>{Array.from({ length: 5 }).map((_, index) => {
                                            return <StarIcon style={{fontSize : "12px"}} key={index} sx={{ color: "#fff" }} />;
                                        })} L5: {commonStats.level5}%</div>
                                        <div>{Array.from({ length: 6 }).map((_, index) => {
                                            return <StarIcon style={{fontSize : "12px"}} key={index} sx={{ color: "#fff" }} />;
                                        })} L6: {commonStats.level6}%</div>
                                    </div>
                                    {address &&
                                        <>
                                            <p className="text-white text-center mb-0 mt-4">Your Team Count</p>
                                            <div className="d-flex justify-content-around mt-1 text-white" style={{ fontSize: "12px" }}>
                                                <div>Level 1 : {accStats.levelRefCount && accStats.levelRefCount[0] ? parseFloat(accStats.levelRefCount[0]) : 0}</div>
                                                <div>Level 2 : {accStats.levelRefCount && accStats.levelRefCount[1] ? parseFloat(accStats.levelRefCount[1]) : 0}</div>
                                                <div>Level 3 : {accStats.levelRefCount && accStats.levelRefCount[2] ? parseFloat(accStats.levelRefCount[2]) : 0}</div>
                                                <div>Level 4 : {accStats.levelRefCount && accStats.levelRefCount[3] ? parseFloat(accStats.levelRefCount[3]) : 0}</div>
                                                <div>Level 5 : {accStats.levelRefCount && accStats.levelRefCount[3] ? parseFloat(accStats.levelRefCount[4]) : 0}</div>
                                                <div>Level 6 : {accStats.levelRefCount && accStats.levelRefCount[3] ? parseFloat(accStats.levelRefCount[5]) : 0}</div>
                                            </div>
                                        </>
                                    }
                                    <p className="card-referral-desc">
                                        Invite your friends using your link and earn up to 5% of any Beans they BAKE and 1% of Re-BAKES. Referral rewards are additional and are not deducted from your friends Beans. To receive Referral Rewards, you must invest 0.15 BNB or more
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div className='col-12 col-md-4 order-1 order-md-2'>
                    <Deposit accStats={accStats} commonStats={commonStats} setUpdater={setUpdater} />
                </div>
            </div>
        </section>
    )
}
